const SliderImagesSample = [
    {
      image:'https://hm.imimg.com/seller/img/ndbottle.png'
        },
    {
      image:'https://hm.imimg.com/seller/img/tip-2.png'
    },{
        image:'https://hm.imimg.com/seller/img/tip-1.png'
      }
  ];

  export default SliderImagesSample;