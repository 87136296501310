const apiData = [
    {
        month: "MAR'23",
        days: 31,

    },
    {
        month: "APR'23",
        days: 30,

    },
    {
        month: "MAY'23",
        days: 31,

    },
    {
        month: "JUN'23",
        days: 30,

    },
    {
        month: "JUL'23",
        days: 31,

    },
    {
        month: "AUG'23",
        days: 31,
    }
]

export default apiData;