const mobileAccessoriesData=[
    {
        image:'https://5.imimg.com/data5/ECOM/Default/2023/5/312515244/EC/LC/IH/182046263/1684593308102-o1cn01irm23u1sayyuqpszd38786857260cib1536x1536-1000x1000.jpeg',
        name: "Leather Case For Apple Iphone ",
        companyName:'Vidhi Telecom',
        price: '₹ 499'
    },{
        image:'https://5.imimg.com/data5/ECOM/Default/2023/6/316331108/QU/UA/RG/96611561/scrl-101-301-mrch-5-1000x1000.jpg',
        name: "fast charging Nylon braided cable",
        companyName:'Sacral Agencies',
        price: '₹ 349'
    },{
        image:'https://5.imimg.com/data5/ECOM/Default/2023/5/308776765/MN/JI/BV/148827935/1681615820603-56489-originnm80prcnt-1000x1000.webp',
        name: "Oppo Earphone",
        companyName:'Sagar Enterprises',
        price: '₹ 153'
    },{
        image:'https://5.imimg.com/data5/ECOM/Default/2023/3/292132376/JZ/NP/CG/145966451/he6d5fde2fa1f412093cb8a6f3dbda9adk-1000x1000.jpg',
        name: "Holster For Smartphones",
        companyName:'Yourdeal India',
        price: '₹ 499'
    },{
        image:'https://5.imimg.com/data5/ECOM/Default/2022/7/RY/CF/SQ/67970605/waterproof-case-for-phone-holder-233089-1000x1000.jpg',
        name: "Waterproof Case For Phone Holder",
        companyName:'LRL Motors Pvt Ltd',
        price: ' ₹ 2,999'
    }
]
export default mobileAccessoriesData;