const babyCareData=[
    {
        image:'https://5.imimg.com/data5/ECOM/Default/2023/8/339719138/FW/ZX/CZ/188483714/3-134d29f5-9e05-46f6-abfa-355e783436f4-1000x1000.jpg',
        name: "Cuddly and Clean Toddlers ",
        companyName:'Premiumav Pvt Ltd',
        price: '₹ 350'
         
    },{
        image:'https://5.imimg.com/data5/ECOM/Default/2022/5/FL/OE/IH/13497945/img-20211001-184730-444-1000x1000.jpg',
        name: " Baby Shoes with Bow color Pink",
        companyName:'Jetaime Fashion LLP',
        price: '₹ 499'
    },{
        image:'https://5.imimg.com/data5/ECOM/Default/2023/3/295704255/UO/HY/ZL/11390531/kk-004-1-1000x1000.jpg',
        name: "Size 4 Diapers, 9 - 14kg, 52 Pieces",
        companyName:'Glow By Tressmart',
        price: '₹ 1,999'
    },{
        image:'https://5.imimg.com/data5/ECOM/Default/2022/11/TX/NK/ED/155039522/coucou-1-1000x1000.jpg',
        name: " Coucou Embroidered Baby Dress",
        companyName:'Finespun Clothing',
        price: '₹ 2,490'
    },{
        image:'https://5.imimg.com/data5/ECOM/Default/2023/2/NS/XC/OZ/9422220/2364167409-688950238-1000x1000.jpg',
        name: "Baby Winter New Bow Shoes",
        companyName:'My Online Collection Store',
        price: '  ₹ 1,499'
    }    
  
]
export default babyCareData;