const beautyProductsData=[
    {
        image:'https://5.imimg.com/data5/ECOM/Default/2023/3/BE/UF/WT/11390531/np-10bb-img-1-1000x1000.jpg',
        name: "Nail Lacquer- Berry Fro ",
        companyName:'Glow By Tressmart',
        price: '₹ 199'
    },{
        image:'https://5.imimg.com/data5/ECOM/Default/2023/2/VE/DH/PF/4417118/saffrongel100g-1000x1000.png',
        name: "Saffron Organic Facial Gel",
        companyName:'Unisaif Healthcare',
        price: '₹ 342'
    },{
        image:'https://5.imimg.com/data5/ECOM/Default/2023/4/300302613/BH/HS/SV/129740345/rosalind-poly-nail-gel-semi-permanent-hybrid-varnish-acrylic-extension-builder-crystal-nail-art-top-base-jpg-640x640-b83c569e-5fcb-4513-adbf-79d261ebc5a1-1000x1000.jpg',
        name: "Rosalind Poly Nail Gel",
        companyName:'Beautify Collections India',
        price: '₹ 799'
    },
    {
        image:'https://5.imimg.com/data5/ECOM/Default/2023/2/EB/JI/UC/25023829/41swwhzqnxl-sl1100-1-1000x1000.jpg',
        name: "Miscos pout Matte Lipstick",
        companyName:' Panchal Hygiene Products',
        price: '₹ 299'
    },
    {
        image:'https://5.imimg.com/data5/ECOM/Default/2023/6/320772487/MU/OO/KW/40788096/62031917-10-image-1000x1000.jpg',
        name: "Maxbell Thickened UV Gel Nails",
        companyName:'Aladdin Shoppers',
        price: '₹ 942'
        

    },


]
export default beautyProductsData;